<script lang='ts'>
  import { onDestroy } from 'svelte'
  import { navigate } from 'svelte-routing'

  import { fetchData } from '@/helpers/fetchHelpers'
  import { _ } from '@/helpers/i18n'

  import Icon from '@/components/icons/Icon.svelte'
  import UserAvatar from '@/components/social/UserAvatar.svelte'
  import Breadcrumb from '@/components/ui/Breadcrumb.svelte'
  import EmptyState from '@/components/ui/EmptyState.svelte'
  import PageInfo from '@/components/ui/PageInfo.svelte'
  import Pagination from '@/components/ui/Pagination.svelte'

  import { FindTask } from '@/definitions/langoid'
  import { findTaskFilterValue } from '@/store'

  export let iso: string,
    type = '',
    id = '1'

  let tasks: FindTask[] = []
  const perPage = 10
  let currentPage = id ? parseInt(id, 10) : 1
  let totalPages = 1
  let filter = 'active'
  const breads = [
    { mobileUrl: `/${iso}/task`, text: '', url: `/${iso}` },
    { text: $_('levelPage.help') }
  ]

  // todo - check if this is error, not sure if I understand this
  $: fetchData('tasks/find', { filter, from: currentPage || 1, group: type }).then((data) => {
    totalPages = Math.ceil(data.count / perPage)
    tasks = data.tasks
  })

  $: if (filter) {
    currentPage = id ? parseInt(id, 10) : 1
  }

  const unsubscribe = findTaskFilterValue.subscribe(config => {
    filter = config
  })

  function goTo (page: number) {
    findTaskFilterValue.set(filter)
    navigate(`/${iso}/task/${type}/find/${page}`)
  }

  const pageChange = (event: CustomEvent) => {
    goTo(event.detail)
  }

  onDestroy(() => {unsubscribe()})
</script>

<Breadcrumb {breads}>
  <h2 class='heading-mobile'>{$_('tasksPage.headlineFind')}</h2>
  <div slot='pageHelperIcons'>
    <PageInfo kebabItem pageName='exercises' />
  </div>
</Breadcrumb>
<div class='_gap24 -unwrapMobile'>
  <h2 class='_desktopOnly'>{$_('tasksPage.headlineFind')}</h2>
  <hr class='_desktopOnly' />
  <div class='controls-menu'>
    <select bind:value={filter} on:change={() => { goTo(1) }} name='filter'>
      <option value='active'>{$_('tasksPage.filter.active')}</option>
      <option value='active-corrected'>{$_('tasksPage.filter.corrected')}</option>
      <option value='active-corrected-by-me'>{$_('tasksPage.filter.correctedByMe')}</option>
      <option value='active-empty'>{$_('tasksPage.filter.empty')}</option>
      <option value='archived'>{$_('tasksPage.filter.archived')}</option>
    </select>
    <select bind:value={type} on:change={() => { goTo(1) }}>
      <option value='all'>{$_('all')}</option>
      <option value='story'>{$_('tasks.story')}</option>
      <option value='listen'>{$_('tasks.listen')}</option>
      <option value='read'>{$_('tasks.read')}</option>
      <option value='translate'>{$_('tasks.translate')}</option>
    </select>
  </div>
  <div class='tasks-list'>
    {#if Object.keys(tasks).length > 0}
      {#each tasks as task}
        <div
          class='single-task -{task.slug}'
          role='link'
          tabindex={0}
          on:click={() => {
            navigate(`/${iso}/task/${task.slug}/correct/${task.id}`)
          }}
          on:keypress={() => {}}
        >
          <div class='type-and-content'>
            {#if type === 'all'}
              <div class='task-type'>
                {#if task.slug === 'story'}
                  <Icon icon='NotePencil' />
                {:else if task.slug === 'listen'}
                  <Icon icon='Headphones' />
                {:else if task.slug === 'read'}
                  <Icon icon='UserSound' />
                {:else if task.slug === 'translate'}
                  <Icon icon='Translate' />
                {/if}
              </div>
            {/if}
            <span class='content'>{task.short || 'Go to task'}</span>
          </div>
          <div class='task-meta-data'>
            <UserAvatar
              metaDataVariant='right'
              user={{ avatar: task.avatar, id: task.id_user, level: task.level, name: task.name, nick: task.nick, points: task.points }}
            />
            <div class='task-popularity'>
              {#if task.correctedByMe}
                <div class='correctedByMe' title={$_('tasksPage.correctedByMe')}>
                  <Icon icon='UserCircleGear' weight='fill' />
                </div>
              {/if}
              <div class='numberLikes' title={$_('tasksPage.taskRating')}>
                <Icon icon='ThumbsUp' weight={task.rating ? 'fill' : 'thin'} />{task.rating}
              </div>
              <div class='correctionsNumber' title={$_('tasksPage.title.correct')}>
                <Icon icon='ListChecks' weight={task.corrections ? 'fill' : 'thin'} />{task.corrections}
              </div>
            </div>
          </div>
        </div>
      {/each}
      <Pagination count={totalPages} current={currentPage} on:change={pageChange} />
    {:else}
      <EmptyState src='/images/empty-file.svg'>
        <div>{$_('tasksPage.noTasks')}</div>
      </EmptyState>
    {/if}
  </div>
</div>

<style lang='scss'>
  .controls-menu {
    display: flex;
    gap: 1.6rem;
    justify-content: space-between;
  }

  .task-type {
    color: var(--Accent-Medium);
  }

  .single-task {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    padding: 1.2rem 0;
    font: var(--Regular-400);
    border-bottom: 0.1rem solid var(--secondary-border);
    cursor: pointer;

    &:hover {
      padding: 1.2rem 2.4rem;
      background-color: var(--hover-background);
      margin-inline: -2.4rem;
    }
  }

  .type-and-content {
    display: flex;
    gap: 1.2rem;
  }

  .task-meta-data {
    display: flex;
    justify-content: space-between;
  }

  .task-popularity {
    display: flex;
    gap: 2.4rem;

    > .correctionsNumber {
      display: flex;
      gap: 0.2rem;
      color: var(--Success-Medium);
    }

    > .numberLikes {
      display: flex;
      gap: 0.4rem;
      color: var(--Primary-Medium);
    }

    > .correctedByMe {
      height: 2.4rem;
      color: var(--Success-Medium);
    }
  }

  @media(max-width: 768px) {
    .task-meta-data {
      grid-template-columns: repeat(3, auto);
    }
  }
</style>
