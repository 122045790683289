<script lang='ts'>
  import { onDestroy, onMount } from 'svelte'
  import { link } from 'svelte-routing'

  import { MIN_NUMBER_OF_USER_LANGUAGES } from '@/config/constants'

  import { resizeTextarea } from '@/helpers/actions/resizeTextarea'
  import { getIdFromIso, getInterfaceLanguageId, getIsoFromId, getLearningLanguageId } from '@/helpers/apiCall'
  import { fetchData } from '@/helpers/fetchHelpers'
  import { _ } from '@/helpers/i18n'

  import AudioPlayer from '@/components/audio/AudioPlayer.svelte'
  import AudioRecorder from '@/components/audio/AudioRecorder.svelte'
  import Flag from '@/components/forum/Flag.svelte'
  import UserAvatar from '@/components/social/UserAvatar.svelte'
  import Breadcrumb from '@/components/ui/Breadcrumb.svelte'
  import PageInfo from '@/components/ui/PageInfo.svelte'

  import { AllLanguagesItem, CreateData, LanguagesList, TaskType } from '@/definitions/langoid'
  import { taskTranslateToLanguage, userStorredConfig } from '@/store'

  export let iso: string,
    id: string = '', // used for tasks type other than story
    type: TaskType

  const interfaceId = getInterfaceLanguageId()
  const languageId = getLearningLanguageId()

  const pageTitleKey = 'tasksPage.title.' + type
  const pageTitle = $_(pageTitleKey)

  const ALPHABET_CHARACTERS_NUMBER = 250
  const SIGN_LANG_CHARACTERS = 50
  const TRANSLATE_LISTEN_CHAR_NAMBERS = 25
  const DELAY_READING_STORAGE = 500
  const minCharactersNumber = (iso === 'jpn' || iso === 'cmn') ? SIGN_LANG_CHARACTERS : ALPHABET_CHARACTERS_NUMBER
  let userId = $userStorredConfig?.id
  let charactersNumber = 0
  let isDisabled = true
  let content = ''
  let isSubmitted = false
  let submitting = false
  let form
  let submitButton: HTMLInputElement
  let original: CreateData = {
    audio_content: '',
    author: '',
    created_at: ' ',
    formatted_content: '',
    id: 0,
    id_language: 0,
    otherLangs: {},
    user: {}
  } as CreateData
  let taskId: number
  let isMyNative = false
  let otherLangs: Record<string, AllLanguagesItem> = {}
  let translationLanguage = 0
  let userLanguages: LanguagesList[] = []
  let user: {}
  let languages: string[] = []
  let breads: any = []
  $: if (iso) {
    languages = [iso, getIsoFromId($taskTranslateToLanguage, true)]
  }

  $: if (content) {
    charactersNumber = checkCharactersNumber(content)
    isDisabled =
      (type === 'story' && (charactersNumber < minCharactersNumber)) ||
      (type === 'listen' && charactersNumber < TRANSLATE_LISTEN_CHAR_NAMBERS)
  }

  $: if (submitting && submitButton && type !== 'listen') {
    submitButton.disabled = true
  }

  $: if (original) {
    user = {
      avatar: original.avatar,
      id: original.id_user,
      level: original.level,
      name: original.name,
      nick: original.nick,
      points: original.points
    }
    breads = [
      { mobileUrl: `/${iso}/task`, text: '', url: `/${iso}` },
      { text: $_('tasks.' + type), url: `/${iso}/task/${type}/select` },
      { text: $_('tasks.task') + `${original.id}` }
    ]
    if (!original.id) {
      breads.pop()
    }
  }

  function checkCharactersNumber (text: string) {
    return text.trim().replace(/\s+/g, '').length
  }

  $:if (iso) {
    if (type !== 'story') {
      fetchData('tasks/details', { id, type }).then((data: CreateData) => {
        original = data
        otherLangs = data.otherLangs || []
        userLanguages = data.userLanguages || []
      })
    }
  }

  const unsubscribe = taskTranslateToLanguage.subscribe(config => {
    if (!submitting) {
      translationLanguage = config || $userStorredConfig.id_lang_learning
    }
  })

  const handleOnSubmit = async function() {
    if (!isDisabled) {
      submitting = true

      let dataSent = {}
      if (type === 'translate') {
        dataSent = { content, original, target: 0, type }
        dataSent.target = translationLanguage
      } else {
        dataSent = { content, original, target: 0, type }
      }
      isDisabled = true
      const data = await fetchData('tasks/create', dataSent)
      isDisabled = false
      isSubmitted = true
      taskId = data.id
    }
  }

  const onAfterSuccess = () => {
    userId = $userStorredConfig?.id
  }

  onMount(() => {
    setTimeout(() => {
      isMyNative = getIsoFromId($userStorredConfig.id_lang_native) === iso
    }, DELAY_READING_STORAGE)
  })

  onDestroy(() => {unsubscribe()})
</script>
<Breadcrumb {breads}>
  <h2>{pageTitle}</h2>
  <div slot='pageHelperIcons'>
    <PageInfo kebabItem pageName='exercises' />
  </div>
</Breadcrumb>
<div class='_gap24'>
  {#if isMyNative && type !== 'translate'}
    <div class='native-warning'>
      <p>{$_('tasksPage.nativeCreateWarning')}</p>
    </div>
    <a class='helpOthersButton' href={`/${iso}/task/${type}/find`} use:link>{$_('levelPage.help')}</a>
  {:else if !isSubmitted}
    <h2 class='_desktopOnly'>{pageTitle}</h2>
    <hr class='_desktopOnly' />
    {#if type !== 'story'}
      <div class='creator-and-translations'>
        <div class='created-by'>
          <h6>{$_('tasksPage.createdBy')}:</h6>
          <UserAvatar
            avatarVariant='smallAvatar'
            date={original.created_at}
            dateVariant='withDate'
            metaDataVariant='right'
            {user}
          />
        </div>
        {#if type === 'translate'}
          <div class='availableTranslations'>{Object.keys(otherLangs || {}).length > 1 ? $_('tasksPage.availableIn') : ''}
            {#if Object.keys(otherLangs || {}).length}
              {#each Object.keys(otherLangs) as lang}
                {#if lang !== original.id_language?.toString()}
                  <a href={`/${otherLangs[lang].iso}/task/${type}/create/${otherLangs[lang].id}`} use:link>
                    <Flag id={parseInt(lang, 10)} />
                  </a>
                {/if}
              {/each}
            {/if}
          </div>
        {/if}
      </div>
    {/if}
    <form bind:this={form} class='form-content _form' class:-submitting={submitting} action='' on:submit|preventDefault={handleOnSubmit}>
      <div class='task-content' class:-translate={type === 'translate'}>
        <div class='task-entry' class:-story={type === 'story'}>
          {#if type === 'translate'}
            <div class='original-language'>
              {#if Object.keys(otherLangs || {}).length}
                {#each Object.keys(otherLangs) as lang}
                  {#if lang === original.id_language?.toString()}
                    <a href={`/${otherLangs[lang].iso}/task/${type}/create/${otherLangs[lang].id}`} use:link>
                      <Flag id={original.id_language} />
                    </a>
                  {/if}
                {/each}
              {/if}
            </div>
            <div class='content'>
              {@html original.formatted_content}
            </div>
          {:else if type === 'read'}
            <div class='content'>
              {@html original.formatted_content}
            </div>
          {:else if type === 'listen'}
            <AudioPlayer src={original.audio_content} variant='progress' />
          {/if}
        </div>
        <hr class='_mobileOnly' class:-translate={type === 'translate'} />
        <div class='task-exit'>
          {#if type !== 'translate'}
            <Flag id={getIdFromIso(languages[0])} />
          {:else}
            <Flag id={getIdFromIso(languages[1])} />
          {/if}
          {#if type === 'read'}
            <AudioRecorder
              {onAfterSuccess}
              params={{ id, interfaceId, languageId, title: original.formatted_content }}
              src={id}
              successMessage={{
              link: `/${iso}/task/${type}/find`,
              profileLink: `/profile/${userId}`,
              type: 'uploadedTask'
              }}
              url='/tasks/uploadAudio'
            >
              <span slot='start'>{$_('recording.start')}</span>
              <span slot='stop'>{$_('recording.stop')}</span>
            </AudioRecorder>
            <small class='createLabel'>
              * {$_('tasksPage.instructions.' + type, { values: { characters: minCharactersNumber } })}
            </small>
          {:else}
            <div class='language-list' class:-hide={userLanguages.length > MIN_NUMBER_OF_USER_LANGUAGES}>
              {#if type === 'translate'}
                <select bind:value={translationLanguage}>
                  {#each userLanguages as lang, index(lang.id)}
                    {#if lang.id !== languageId}
                      <option value={lang.id}>{lang.language}</option>
                    {/if}
                  {/each}
                </select>
              {/if}
            </div>
            <div class='text-input'>
              <textarea
                id='content'
                cols='30'
                rows='10'
                bind:value={content}
                use:resizeTextarea
                name='content'
              />
              <div class='characters-and-info'>
                <small class='wordNumber'>{$_('tasksPage.charactersNumber')}: {charactersNumber}</small>
                <small class='createLabel'>
                  * {$_('tasksPage.instructions.' + type, { values: { characters: minCharactersNumber } })}
                </small>
              </div>
            </div>
          {/if}
        </div>
      </div>
      {#if type !== 'read'}
        <input
          bind:this={submitButton}
          class='submit-button'
          disabled={isDisabled}
          type='submit'
          value={$_('tasksPage.buttonPublish')}
        />
      {/if}
    </form>
  {:else}
    <div class='success-modal'>
      <h3 class='successHeading'>{$_('tasksPage.modalHeadline')}</h3>
      <img alt='Writing' src='/images/successful-story.svg' />
      <div class='access-link'>
        <p>{$_('tasksPage.modalFind')}&nbsp;<a href='/{iso}/task/{type}/correct/{taskId}' use:link>{$_('tasksPage.modalLinkHere')}</a></p>
        <!-- todo - add link to user profile below -->
        <p>{$_('tasksPage.modalProfile')}&nbsp;<a href='/profile/{userId}' use:link>{$_('tasksPage.modalLinkProfile')}</a></p>
      </div>
    </div>
  {/if}
</div>

<style lang='scss'>
  .creator-and-translations {
    display: flex;
    justify-content: space-between;

    > .availableTranslations {
      display: flex;
      gap: 0.4rem;
      align-items: center;
    }
  }

  .form-content {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 1.6rem;

    &.-submitting::after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(white, 0.85) url(/images/spinner.gif) no-repeat center center;
      content: '';
    }

  }

  .created-by {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
  }

  .task-content {
    display: flex;
    flex-direction: column;
    gap: 2.4rem;

    &.-translate {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 2.4rem;
    }
  }

  .language-list {
    &.-hide {
      display: none;
    }
  }

  .success-modal {
    text-align: center;
  }

  .task-entry {
    background: var(--Primary-Lighter);

    &.-story {
      display: none;
    }

    > .content {
      padding: 0.8rem 1.2rem;
      border: 0.1rem solid var(--Gray-Light);
      border-radius: 0.8rem;
    }
  }

  .characters-and-info {
    display: flex;
    gap: 2.4rem;
    justify-content: space-between;
    padding-top: 0.4rem;
  }

  .submit-button {
    align-self: flex-end;
  }

  @media (max-width: 768px) {
    ._gap24 {
      > hr {
        display: none;
      }
    }
    .task-content {
      &.-translate {
        display: flex;
        flex-direction: column;
        gap: 2.4rem;
      }
    }

    ._mobileOnly {
      display: none;

      &.-translate {
        display: block;
      }
    }

    .task-entry {
      margin-bottom: 1.8rem;
    }
  }
</style>
